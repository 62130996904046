import axios from "axios";
const baseUrl =
  "https://53fde1b606cff91e7a3b23ff9c242152.grupoalianzacolombia.com/upload.php";

const uploadFileGac = async (image) => {
  try {
    const response = await axios.post(baseUrl, image);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export { uploadFileGac };
